.modelBackground {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0.625rem;
  bottom: 0;
  left: 0;
  border-radius: var(--br-40xl);
  background-color: var(--color-whitesmoke-100);
}
.frameChild {
  height: 7.006rem;
  width: 29.044rem;
  position: relative;
  background-color: var(--color-white);
  transform: rotate(-2deg);
  display: none;
  max-width: 100%;
  z-index: 1;
}
.incendiaryFantasticallyBeautIcon {
  height: 49rem;
  width: 54.438rem;
  position: absolute;
  margin: 0 !important;
  right: 5.688rem;
  bottom: 0;
  object-fit: contain;
  z-index: 1;
}
.titleBackground {
  height: 7.35rem;
  width: 30.988rem;
  position: absolute;
  margin: 0 !important;
  bottom: 7.094rem;
  left: -1.594rem;
  background-color: var(--color-darkkhaki);
  transform: rotate(-2deg);
  transform-origin: 0 0;
  z-index: 1;
}
.discoverPremiumPet {
  margin: 0;
  height: 29.563rem;
  flex: 1;
  position: relative;
  font-size: 6rem;
  line-height: 7.5rem;
  font-weight: 900;
  font-family: inherit;
  display: inline-block;
  max-width: 100%;
  z-index: 2;
}
.petFoodTitle,
.titleBackgroundParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.titleBackgroundParent {
  align-self: stretch;
  flex-direction: row;
  position: relative;
}
.petFoodTitle {
  width: 37.781rem;
  flex-direction: column;
  padding: 0 var(--padding-3xl);
  box-sizing: border-box;
}
.liveForInfluential {
  flex: 1;
  position: relative;
  letter-spacing: -0.05em;
  display: inline-block;
  max-width: 100%;
  z-index: 1;
}
.fashionDescription {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 0 var(--padding-6xl);
  box-sizing: border-box;
  max-width: 100%;
  font-size: var(--font-size-13xl);
  color: var(--color-gray-900);
}
.shopNowButtonChild {
  align-self: stretch;
  flex: 1;
  z-index: 1;
}
.petFoodContent,
.petFoodDescription,
.shopNowButton {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.shopNowButton {
  width: 12.719rem;
  height: 3.125rem;
  flex-direction: row;
  padding: 0 var(--padding-12xl);
  box-sizing: border-box;
}
.petFoodContent,
.petFoodDescription {
  flex-direction: column;
}
.petFoodDescription {
  align-self: stretch;
  gap: var(--gap-22xl);
  max-width: 100%;
}
.petFoodContent {
  flex: 1;
  padding: var(--padding-4xs) 0 0;
  box-sizing: border-box;
  max-width: calc(100% - 124px);
}
.starOneIcon,
.starTwoIcon {
  height: 3.5rem;
  width: 3.625rem;
  position: relative;
  z-index: 2;
}
.otherStars,
.petFood,
.rating {
  display: flex;
  align-items: flex-start;
}
.otherStars {
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-end;
}
.petFood,
.rating {
  width: 6.313rem;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-619xl);
}
.petFood {
  width: 47.219rem;
  flex-direction: row;
  gap: var(--gap-4xl);
  max-width: 100%;
}
.starFourIcon,
.starThreeIcon {
  width: 3.625rem;
  height: 3.5rem;
  position: relative;
  z-index: 2;
}
.starFourWrapper,
.starThreeParent {
  display: flex;
  align-items: flex-start;
}
.starFourWrapper {
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-581xl) 0 0;
}
.starThreeParent {
  align-self: stretch;
  flex-direction: row;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.modelBackgroundParent,
.moreStars {
  width: 13.313rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-17xl) 0 0;
  box-sizing: border-box;
}
.modelBackgroundParent {
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  padding: var(--padding-49xl) var(--padding-74xl) var(--padding-15xl);
  position: relative;
  max-width: 100%;
  gap: var(--gap-xl);
  text-align: left;
  font-size: var(--font-size-77xl);
  color: var(--color-black);
  font-family: var(--font-poppins);
}
@media screen and (max-width: 1400px) {
  .modelBackgroundParent {
    flex-wrap: wrap;
    padding-left: var(--padding-27xl);
    padding-right: var(--padding-27xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 925px) {
  .discoverPremiumPet {
    font-size: var(--font-size-29xl);
    line-height: 4.5rem;
  }
  .liveForInfluential {
    font-size: var(--font-size-7xl);
  }
  .petFoodDescription {
    gap: var(--gap-xl);
  }
  .petFoodContent {
    max-width: 100%;
  }
  .rating {
    display: none;
  }
  .starFourWrapper {
    padding-top: var(--padding-371xl);
    box-sizing: border-box;
  }
  .modelBackgroundParent {
    padding: var(--padding-25xl) var(--padding-4xl) var(--padding-3xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .discoverPremiumPet {
    font-size: var(--font-size-10xl);
    line-height: 3rem;
  }
  .liveForInfluential {
    font-size: var(--font-size-lgi);
  }
  .modelBackgroundParent {
    padding-top: var(--padding-10xl);
    padding-bottom: var(--padding-xl);
    box-sizing: border-box;
  }
}


/* Add this to the top of your CSS file */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.modelBackgroundParent {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: var(--padding-49xl) var(--padding-74xl) var(--padding-15xl);
  position: relative;
  max-width: 100%;
  gap: var(--gap-xl);
  text-align: left;
  font-size: var(--font-size-77xl);
  color: var(--color-black);
  font-family: var(--font-poppins);
}

/* Add media queries to adjust the layout and styling based on screen size */
@media screen and (max-width: 1400px) {
  .modelBackgroundParent {
    flex-wrap: wrap;
    padding-left: var(--padding-27xl);
    padding-right: var(--padding-27xl);
  }
  .petFood {
    flex-direction: row;
  }
  .petFoodContent {
    max-width: 100%;
  }
}

@media screen and (max-width: 925px) {
  .discoverPremiumPet {
    font-size: var(--font-size-29xl);
    line-height: 4.5rem;
  }
  .liveForInfluential {
    font-size: var(--font-size-7xl);
  }
  .petFoodDescription {
    gap: var(--gap-xl);
  }
  .petFoodContent {
    max-width: 100%;
  }
  .rating {
    display: none;
  }
  .starFourWrapper {
    padding-top: var(--padding-371xl);
  }
  .modelBackgroundParent {
    padding: var(--padding-25xl) var(--padding-4xl) var(--padding-3xl);
  }
}

@media screen and (max-width: 450px) {
  .discoverPremiumPet {
    font-size: var(--font-size-10xl);
    line-height: 3rem;
  }
  .liveForInfluential {
    font-size: var(--font-size-lgi);
  }
  .modelBackgroundParent {
    padding-top: var(--padding-10xl);
    padding-bottom: var(--padding-xl);
  }
  .petFood {
    flex-direction: column;
    align-items: center;
  }
  .petFoodContent {
    max-width: 100%;
    padding: 0 var(--padding-2xl);
  }
  .shopNowButton {
    width: 100%;
  }
}