.heroSection {
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 0 0 var(--padding-12xs);
  gap: 3.5rem;
}
.brandsArrivals,
.heroSection,
.heroSectionWrapper {
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}
.heroSectionWrapper {
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 0.25rem 3.937rem;
}
.brandsArrivals {
  align-self: stretch;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 0 var(--padding-171xl);
  gap: 4.312rem;
}
.bannerBackground {
  height: 100%;
  width: 58.306rem;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    1.32deg,
    #ebc76b,
    #ebc76b 3.93%,
    #ebc76b 7.23%,
    #ebc76b 10.23%,
    #ebc76b 13.14%,
    #ebc76b 16.22%,
    #ebc76b 19.84%,
    #ebc76b 24.38%,
    #ebc76b 31%,
    #ebc76b 37.19%,
    #ebc76b 43.33%,
    #ebc76b 50.36%,
    #ebc76b 56.57%,
    #ebc76b 62.71%,
    #ebc76b 67.12%,
    #ebc76b 73.77%,
    #ebc76b 79.08%,
    #ebc76b 85.64%,
    #ebc76b 93.5%,
    #ebc76b
  );
}
.image12Icon {
  height: 60.5rem;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
  z-index: 1;
}
.bannerStarsIcon {
  height: 6.388rem;
  width: 6.406rem;
  position: absolute;
  margin: 0 !important;
  top: 9.494rem;
  left: 8.525rem;
  object-fit: contain;
  z-index: 2;
}
.bannerStarsIcon1 {
  top: 5.25rem;
  right: 7.706rem;
}
.bannerStarsIcon1,
.bannerStarsIcon2,
.bannerStarsIcon3 {
  height: 6.388rem;
  width: 6.406rem;
  position: absolute;
  margin: 0 !important;
  object-fit: contain;
  z-index: 2;
}
.bannerStarsIcon2 {
  bottom: 23.488rem;
  left: 11.713rem;
}
.bannerStarsIcon3 {
  right: 4.513rem;
  bottom: 14.175rem;
}
.bannerRating {
  width: 61.963rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 61.963rem;
}
.bannerRating,
.frameChild,
.specialSale {
  position: relative;
  max-width: 100%;
}
.frameChild {
  height: 8.15rem;
  width: 33.838rem;
  background-color: var(--color-white);
  transform: rotate(-2deg);
  display: none;
}
.specialSale {
  flex: 1;
  line-height: 8.574rem;
  font-weight: 900;
  display: inline-block;
  z-index: 1;
}
.rectangleParent {
  width: 39.969rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.725rem var(--padding-4xl) 0;
  box-sizing: border-box;
  max-width: 100%;
}
.exclusiveOffersThis {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.02em;
  line-height: 3.27rem;
  font-weight: 500;
  font-family: inherit;
}
.july19,
.termsConditions {
  position: relative;
  display: inline-block;
}
.july19 {
  width: 26.813rem;
  letter-spacing: 0.02em;
  line-height: 2.4rem;
  max-width: 100%;
}
.termsConditions {
  margin: 0;
  align-self: stretch;
  height: 2.4rem;
  font-size: inherit;
  letter-spacing: -0.02em;
  line-height: 3.27rem;
  font-weight: 400;
  font-family: inherit;
  flex-shrink: 0;
}
.saleContent,
.salePeriod {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.salePeriod {
  width: 32.05rem;
  padding: 0 var(--padding-xl) 0 0;
  box-sizing: border-box;
  gap: 0.65rem;
  color: var(--color-black);
}
.saleContent {
  align-self: stretch;
  gap: 1.65rem;
  flex-shrink: 0;
}
.saleDescriptionChild {
  width: 16.6rem;
  height: 5.956rem;
  flex-shrink: 0;
}
.saleDescription {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 3.193rem;
  max-width: 100%;
}
.frameParent,
.saleDescriptionButton,
.saleDetails {
  align-items: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}
.saleDescriptionButton {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0 0 0 var(--padding-4xl);
  font-size: var(--font-size-17xl);
  color: #231300;
}
.frameParent,
.saleDetails {
  flex-direction: column;
}
.frameParent {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
  padding: 0 0 0.006rem;
  gap: 2.068rem;
  z-index: 1;
}
.saleDetails {
  width: 41.65rem;
  padding: 8.006rem 0 0;
  min-width: 41.65rem;
}
.banner,
.bannerWrapper,
.saleDetails,
.shoppingApp {
  display: flex;
  justify-content: flex-start;
}
.banner {
  flex: 1;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
  gap: 4.175rem;
  max-width: 100%;
}
.bannerWrapper,
.shoppingApp {
  box-sizing: border-box;
}
.bannerWrapper {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 0 var(--padding-171xl);
  max-width: 100%;
  text-align: left;
  font-size: 6.975rem;
  color: var(--color-black);
  font-family: var(--font-poppins);
}
.shoppingApp {
  width: 100%;
  position: relative;
  background-color: var(--color-white);
  overflow: hidden;
  flex-direction: column;
  align-items: flex-end;
  padding: var(--padding-xl) 0 0;
  line-height: normal;
  letter-spacing: normal;
}
@media screen and (max-width: 1850px) {
  .bannerRating,
  .image12Icon,
  .saleDetails {
    flex: 1;
  }
  .banner {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1400px) {
  .heroSectionWrapper {
    padding-bottom: 2.563rem;
    box-sizing: border-box;
  }
  .brandsArrivals {
    gap: 2.125rem;
    padding-bottom: var(--padding-104xl);
    box-sizing: border-box;
  }
  .bannerRating {
    min-width: 100%;
  }
  .bannerWrapper {
    padding-bottom: var(--padding-104xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 925px) {
  .heroSection {
    gap: var(--gap-9xl);
  }
  .heroSectionWrapper {
    padding-bottom: 1.688rem;
    box-sizing: border-box;
  }
  .brandsArrivals {
    gap: var(--gap-mid);
    padding-bottom: var(--padding-61xl);
    box-sizing: border-box;
  }
  .specialSale {
    font-size: 3.5rem;
    line-height: 5.125rem;
  }
  .exclusiveOffersThis,
  .july19,
  .termsConditions {
    font-size: var(--font-size-10xl);
    line-height: 2.625rem;
  }
  .july19,
  .termsConditions {
    line-height: 1.938rem;
  }
  .termsConditions {
    line-height: 2.625rem;
  }
  .saleDescription {
    gap: var(--gap-7xl);
  }
  .frameParent {
    gap: var(--gap-mid);
  }
  .saleDetails {
    padding-top: 5.188rem;
    box-sizing: border-box;
    min-width: 100%;
  }
  .banner {
    gap: 2.063rem;
  }
  .bannerWrapper {
    padding-bottom: var(--padding-61xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .specialSale {
    font-size: var(--font-size-14xl);
    line-height: 3.438rem;
  }
  .exclusiveOffersThis,
  .july19,
  .termsConditions {
    font-size: var(--font-size-3xl);
    line-height: 1.938rem;
  }
  .july19,
  .termsConditions {
    line-height: 1.438rem;
  }
  .termsConditions {
    line-height: 1.938rem;
  }
  .saleDetails {
    padding-top: 3.375rem;
    box-sizing: border-box;
  }
  .banner {
    gap: var(--gap-mid);
  }
}
