.footerBackground {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--color-black);
}
.petstore {
  height: 1.275rem;
  position: relative;
  font-size: var(--font-size-7xl);
  letter-spacing: 0.03em;
  font-weight: 900;
  font-family: var(--font-poppins);
  display: inline-block;
  flex-shrink: 0;
}
.completeYourStyle {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.02em;
  line-height: 2rem;
  color: var(--color-gray-200);
  font-size: 18px;
}
.socialIconBackgrounds {
  position: absolute;
  height: 100%;
  width: 80%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-mini);
  background-color: #fff;
}
.socialIcons1 {
  position: absolute;
  height: 48.08%;
  width: 25.77%;
  top: 25%;
  right: 37.69%;
  bottom: 26.92%;
  left: 29.54%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  z-index: 1;
  /* filter:invert() */
}
.socialIconBackgroundsParent {
  height: 2.5rem;
  flex: 1;
  position: relative;
  cursor: pointer;
}
.frameChild {
  position: absolute;
  height: 100%;
  width: 80%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-mini);
  background-color: #fff;
}
.vectorIcon {
  position: absolute;
  height: 42.12%;
  width: 42.12%;
  top: 28.85%;
  right: 29.04%;
  bottom: 29.04%;
  left: 19%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  z-index: 1;
  /* filter:invert() */
}
.rectangleParent {
  height: 2.5rem;
  flex: 1;
  position: relative;
  cursor: pointer;
}
.frameItem {
  position: absolute;
  height: 100%;
  width: 80%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-mini);
  background-color: #fff;
}
.vectorIcon1 {
  position: absolute;
  height: 39.04%;
  width: 48.08%;
  top: 30.77%;
  right: 21.15%;
  bottom: 30.19%;
  left: 18.77%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  z-index: 1;
  /* filter:invert() */
}
.vectorIcon2 {
  position: absolute;
  height: 39.04%;
  width: 48.08%;
  top: 30.77%;
  right: 21.15%;
  bottom: 30.19%;
  left: 16.77%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  z-index: 1;
  /* filter:invert() */
}
.rectangleGroup {
  height: 2.5rem;
  flex: 1;
  position: relative;
  cursor: pointer;
  
}
.frameInner {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-mini);
  background-color: var(--color-khaki);
}
.in {
  position: relative;
  letter-spacing: 0.02em;
  line-height: 1.75rem;
  display: inline-block;
  min-width: 1.438rem;
  z-index: 1;
}
.rectangleContainer {
  flex-direction: row;
  padding: var(--padding-7xs) var(--padding-smi) var(--padding-lg)
    var(--padding-base);
  position: relative;
  cursor: pointer;
}
.footerDescription,
.rectangleContainer,
.socialIcons {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.socialIcons {
  width: 15.625rem;
  flex-direction: row;
  gap: var(--gap-sm);
  color: var(--color-black);
}
.footerDescription {
  width: 25.938rem;
  flex-direction: column;
  gap: var(--gap-13xl);
  min-width: 25.938rem;
  max-width: 100%;
}
.company {
  position: relative;
  letter-spacing: 0.02em;
  line-height: 2rem;
  font-size: 1rem;
  color: var(--color-gainsboro);
  display: inline-block;
  min-width: 6.563rem;
}
.about {
  min-width: 4.188rem;
  font-size: 1rem;
}
.about,
.careers,
.contactUs,
.support {
  position: relative;
  letter-spacing: 0.02em;
  line-height: 1rem;
  display: inline-block;
}
.contactUs {
  min-width: 7.563rem;
  white-space: nowrap;
  font-size: 1rem;
}
.careers,
.support {
  min-width: 5.5rem;
}
.careers {
  line-height: 1.75rem;
  min-width: 5.375rem;
}
.companyParent,
.contactSupport,
.supportCareers {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
}
.companyParent,
.contactSupport {
  gap: var(--gap-6xl);
}
.companyParent {
  gap: var(--gap-xl);
}
.quickLink {
  position: relative;
  letter-spacing: 0.02em;
  line-height: 2rem;
  font-size: 1rem;
  display: inline-block;
  min-width: 7.25rem;
}
.quickLinks {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  /* padding: 0 var(--padding-11xs); */
  color: var(--color-gainsboro);
}
.shareLocation {
  position: relative;
  letter-spacing: 0.02em;
  line-height: 2.5rem;
}
.quickLinks1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-4xs) 0 var(--padding-12xs);
}
.ordersTracking {
  width: 11rem;
  position: relative;
  letter-spacing: 0.02em;
  line-height: 2.5rem;
  display: inline-block;
  flex-shrink: 0;
}
.quickLinks2 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-4xs) 0 var(--padding-11xs);
}
.sizeGuide {
  position: relative;
  letter-spacing: 0.02em;
  line-height: 2.5rem;
  display: inline-block;
  min-width: 7.313rem;
}
.quickLinks3 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-12xs);
}
.faqs {
  position: relative;
  letter-spacing: 0.02em;
  line-height: 1rem;
  display: inline-block;
  min-width: 3.625rem;
  font-size: 1rem;
}
.quickLinksParent {
  width: 11.063rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl-8);
  color: var(--color-gray-400);
}
.privacyPolicy {
  position: absolute;
  margin: 0 !important;
  bottom: -0.75rem;
  left: 0;
  font-size: 1rem;
  letter-spacing: 0.02em;
  line-height: 1rem;
}

.termsConditions {
  position: relative;
  letter-spacing: 0.02em;
  line-height: 1rem;
  font-size: 1rem;
}
.legal{
  position: relative;
  letter-spacing: 0.02em;
  font-size: 1rem;
  line-height: 2rem;
}
.legal {
  color: var(--color-whitesmoke-200);
  display: inline-block;
  min-width: 3.813rem;
}
.termsConditions {
  color: "#b8b8b8";
}
.privacyPolicyParent {
  height: 9.313rem;
  flex-direction: column;
  justify-content: flex-start;
  /* padding: 0 0 0 var(--padding-11xs); */
  box-sizing: border-box;
  position: relative;
  gap: var(--gap-3xl);
  color: var(--color-gray-500);
}
.footerLinks,
.linksContainer,
.privacyPolicyParent {
  display: flex;
  align-items: flex-start;
}
.linksContainer {
  align-self: stretch;
  flex-direction: row;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.footerLinks {
  width: 55.625rem;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-7xs) 0 0;
  box-sizing: border-box;
  min-width: 55.625rem;
  max-width: 100%;
  color: var(--color-gray-200);
}
.footerBackgroundParent,
.footerDescriptionParent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 100%;
}
.footerDescriptionParent {
  width: 107.5rem;
  justify-content: space-between;
  gap: var(--gap-xl);
  z-index: 1;
}
.footerBackgroundParent {
  align-self: stretch;
  justify-content: center;
  padding: 20px 20px;
  box-sizing: border-box;
  position: relative;
  min-height: 21.063rem;
  text-align: left;
  font-size: var(--font-size-5xl);
  color: var(--color-white);
  font-family: var(--font-roboto);
}
@media screen and (max-width: 1400px) {
  .footerDescription {
    flex: 1;
  }
  .footerLinks {
    flex: 1;
    min-width: 100%;
  }
  .footerDescriptionParent {
    flex-wrap: wrap;
  }
  .privacyPolicyParent {
    height: 6rem;
  }
}
@media screen and (max-width: 2560px) {
  .footerDescription {
    flex: 1;
  }
  .footerLinks {
    flex: 1;
    min-width: 100%;
  }
  .footerDescriptionParent {
    flex-wrap: wrap;
  }
  .privacyPolicyParent {
    height: 6rem;
  }
}
@media screen and (max-width: 925px) {
  .footerDescription {
    min-width: 100%;
  }
  .linksContainer {
    flex-wrap: wrap;
  }
  .footerBackgroundParent {
    padding-top: 20px;
    padding-bottom: 20px;
    box-sizing: border-box;
  }
  .privacyPolicyParent {
    height: 6rem;
  }
}
@media screen and (max-width: 450px) {
  .petstore {
    font-size: var(--font-size-2xl);
  }
  .completeYourStyle,
  .in {
    font-size: 20px;
    line-height: 2rem;
  }
  .in {
    line-height: 1.375rem;
  }
  .footerDescription {
    gap: var(--gap-base);
  }
  .about,
  .careers,
  .company,
  .contactUs,
  .support {
    font-size: var(--font-size-lgi);
    line-height: 1rem;
  }
  .careers {
    line-height: 1.375rem;
  }
  .faqs,
  .ordersTracking,
  .quickLink,
  .shareLocation,
  .sizeGuide {
    font-size: var(--font-size-lgi);
    line-height: 1rem;
  }
  .faqs {
    line-height: 1rem;
  }
  .legal,
  .privacyPolicy,
  .termsConditions {
    font-size: var(--font-size-lgi);
    line-height: 1rem;
  }
  .privacyPolicyParent {
    height: 4.7rem;
  }
}
