.brandListBackground {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #ff6501;

}

.container{
  background-color: aqua;
}
.carousel-container {
  max-width: 80%; /* Adjust the width as needed */
  margin: 0 auto;
}


.image{
  width: 50%;
  min-width: 200px;
  max-width: 200px;
  min-height: 50px;
  max-height: 50px;
  margin-top: 5px;
  object-fit: contain;
  background-color: #ff6501;
}


.brandOneIcon,
.brandTwoImage {
  align-self: stretch;
  position: relative;
  max-height: 100%;
  object-fit: cover;
}
.brandOneIcon {
  width: 7.394rem;
  min-height: 4.375rem;
}
.brandTwoImage {
  flex: 1;
  max-width: 100%;
  overflow: hidden;
  mix-blend-mode: multiply;
}
.brandTwo {
  height: 3.856rem;
  width: 9.375rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-4xs) 0 0;
  box-sizing: border-box;
}
.brandThreeImage {
  align-self: stretch;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
  mix-blend-mode: multiply;
}
.brandThree {
  height: 4.138rem;
  width: 11rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-8xs-4) 0 0;
  box-sizing: border-box;
}
.brandFiveIcon,
.brandFourIcon,
.brandSixImage {
  align-self: stretch;
  position: relative;
  max-height: 100%;
  mix-blend-mode: multiply;
}
.brandFourIcon {
  width: 14.688rem;
  object-fit: contain;
  min-height: 4.375rem;
}
.brandFiveIcon,
.brandSixImage {
  object-fit: cover;
}
.brandFiveIcon {
  width: 7.938rem;
  min-height: 4.438rem;
}
.brandSixImage {
  flex: 1;
  max-width: 100%;
  overflow: hidden;
}
.brandSix,
.brands,
.brands1 {
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
}
.brandSix {
  height: 4.175rem;
  width: 10.375rem;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-9xs-8) 0 0;
}
.brands,
.brands1 {
  flex-direction: row;
  max-width: 100%;
}
.brands1 {
  width: 98.581rem;
  background-color: var(--color-khaki);
  justify-content: space-between;
  padding: var(--padding-12xl-2) 0 var(--padding-12xl-1);
  gap: var(--gap-xl);
  z-index: 1;
}
.brands {
  align-self: stretch;
  justify-content: center;
  padding: var(--padding-11xl) var(--padding-xl) var(--padding-4xl);
  position: relative;
}
@media screen and (max-width: 1400px) {
  .brands1 {
    flex-wrap: wrap;
  }
}
