@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

body {
  margin: 0;
  line-height: normal;
  font-family: var(--font-poppins);
}
:root {
  /* fonts */
  --font-roboto: Roboto;
  --font-poppins: Poppins;

  /* font sizes */
  --font-size-5xl: 1.5rem;
  --font-size-lgi: 1rem;
  --font-size-footerlink: 1rem;
  --font-size-7xl: 1.625rem;
  --font-size-2xl: 1.313rem;
  --font-size-14xl: 2.063rem;
  --font-size-xl: 1.25rem;
  --font-size-13xl: 2rem;
  --font-size-36xl: 3.438rem;
  --font-size-25xl: 2.75rem;
  --font-size-29xl: 3rem;
  --font-size-19xl: 2rem;
  --font-size-10xl: 1.813rem;
  --font-size-17xl: 2.25rem;
  --font-size-3xl: 1.375rem;
  --font-size-77xl: 6rem;
  --font-size-21xl: 2.5rem;

  /* Colors */
  --color-white: #fff;
  --color-gray-100: #fefefe;
  --color-gray-200: #8e8e8e;
  --color-gray-300: #7f7f7f;
  --color-gray-400: #7a7a7a;
  --color-gray-500: #787878;
  --color-gray-600: #767676;
  --color-gray-700: #242323;
  --color-gray-800: #191919;
  --color-gray-900: #191818;
  --color-whitesmoke-100: #f4f6f5;
  --color-whitesmoke-200: #eaeaea;
  --color-gainsboro: #d9d9d9;
  --color-black: #000;
  --color-khaki: #ebc76b;
  --color-snow: #fffcf8;
  --color-sandybrown: #ebbf6b;
  --color-silver: #c4c4c4;
  --color-darkkhaki: #ebc06b;

  /* Gaps */
  --gap-xl: 1.25rem;
  --gap-3xl: 1.375rem;
  --gap-xl-8: 1.3rem;
  --gap-6xl: 1.562rem;
  --gap-13xl: 2rem;
  --gap-base: 1rem;
  --gap-sm: 0.875rem;
  --gap-51xl: 4.375rem;
  --gap-mid: 1.062rem;
  --gap-16xl: 2.187rem;
  --gap-2xl: 1.312rem;
  --gap-139xl: 9.875rem;
  --gap-20xl: 2.437rem;
  --gap-60xl: 4.937rem;
  --gap-1487xl: 94.125rem;
  --gap-357xl: 23.5rem;
  --gap-734xl: 47.062rem;
  --gap-169xl: 11.75rem;
  --gap-85xl: 6.5rem;
  --gap-33xl: 3.25rem;
  --gap-7xl: 1.625rem;
  --gap-28xl: 2.937rem;
  --gap-4xl: 1.437rem;
  --gap-9xl: 1.75rem;
  --gap-6xs: 0.437rem;
  --gap-82xl-1: 6.318rem;
  --gap-32xl: 3.187rem;
  --gap-5xl: 1.5rem;
  --gap-619xl: 39.875rem;
  --gap-22xl: 2.562rem;
  --gap-35xl: 3.375rem;
  --gap-8xl: 1.687rem;
  --gap-2xs: 0.687rem;

  /* Paddings */
  --padding-27xl: 2.875rem;
  --padding-100xl: 7.437rem;
  --padding-xl: 1.25rem;
  --padding-58xl: 4.812rem;
  --padding-7xs: 0.375rem;
  --padding-11xs: 0.125rem;
  --padding-12xs: 0.062rem;
  --padding-4xs: 0.562rem;
  --padding-smi: 0.812rem;
  --padding-lg: 1.125rem;
  --padding-base: 1rem;
  --padding-122xl: 8.812rem;
  --padding-435xl: 28.375rem;
  --padding-162xl: 11.312rem;
  --padding-73xl: 5.75rem;
  --padding-94xl: 7.062rem;
  --padding-99xl: 7.375rem;
  --padding-208xl: 14.187rem;
  --padding-3xl: 1.375rem;
  --padding-124xl: 8.937rem;
  --padding-20xl: 2.437rem;
  --padding-41xl: 3.75rem;
  --padding-245xl: 16.5rem;
  --padding-153xl: 10.75rem;
  --padding-10xs: 0.187rem;
  --padding-base-4: 0.962rem;
  --padding-base-9: 0.993rem;
  --padding-171xl: 11.875rem;
  --padding-61xl: 5rem;
  --padding-104xl: 7.687rem;
  --padding-4xl: 1.437rem;
  --padding-11xl: 1.875rem;
  --padding-12xl-2: 1.95rem;
  --padding-12xl-1: 1.943rem;
  --padding-9xs-8: 0.237rem;
  --padding-8xs-4: 0.275rem;
  --padding-49xl: 4.25rem;
  --padding-74xl: 5.812rem;
  --padding-15xl: 2.125rem;
  --padding-25xl: 2.75rem;
  --padding-10xl: 1.812rem;
  --padding-17xl: 2.25rem;
  --padding-581xl: 37.5rem;
  --padding-371xl: 24.375rem;
  --padding-12xl: 1.937rem;
  --padding-6xl: 1.562rem;
  --padding-mid: 1.062rem;
  --padding-base-5: 0.968rem;

  /* Border radiuses */
  --br-mini: 15px;
  --br-5xs: 8px;
  --br-xl: 20px;
  --br-40xl: 59px;
  --br-6xs: 7px;
}


.react-multi-carousel-item {
  transform-style: preserve-3d;
  backface-visibility: hidden;
  text-align: center;
}
